export default function colWidth(
  width: number,
  totalColumns: number,
  gutter: string = '10px',
  disableGridWrapper: boolean = false, // useful for imageRow
) {
  let o = {
    width: `calc(99.9% * ${width}/${totalColumns} - (${gutter} - ${gutter} * ${width}/${totalColumns}))`,
    [`&:nth-of-type(1n)`]: {
      marginRight: `${gutter}`,
    },
  }
  return disableGridWrapper === true
    ? {
        ...o,
        [`&:last-child`]: {
          marginRight: `0`,
        },
      }
    : {
        ...o,

        [`&:nth-of-type(${totalColumns / width}n)`]: {
          marginRight: `0`,
        },
      }
}
