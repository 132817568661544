import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import airplane from '../images/airplane.png'

import { Helmet } from 'react-helmet'

import { colors, mediaQueries } from '$theme/legacy'
import { rhythm } from '$utils/typography'
import { gridColumn as grid } from '../utils'

import Layout from '../components/Layout'

const PreviewGrid = styled.div({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
})

const Container = styled.div({
  width: '100%',
  margin: '10px 10px',
})

const Header = styled.div({
  color: 'white',
  maxWidth: '1024px',
  margin: `${rhythm(2)} auto`,
})

const H1 = styled.h1({
  color: colors.dark,
  textTransform: 'uppercase',
  fontWeight: '300',
  fontSize: '5rem',
  lineHeight: '5rem',
})

const P = styled.p({
  color: colors.darker,
})

const MediaAdjustment = styled.div({
  [mediaQueries.MIN_TABLET_MEDIA_QUERY]: {
    display: 'flex',
    flexDirection: 'reverse',
    '& > div': {
      ...grid(1, 2),
    },
  },
})

const flyAnimation = keyframes({
  '0%': { transform: `translate3d(0,0,0)` },
  '100%': { transform: `translate3d(0,15px,0)` },
})

const ImageWithAnimation = styled.img({
  transform: `translate3d(0,0,0)`,
  animation: `${flyAnimation} 800ms ease-in-out 0s infinite alternate`,
  top: 0,
})

const Code = styled.span({ color: colors.greens.zero, fontWeight: 'bold' })

export default function NotFoundPage() {
  return (
    <Layout>
      <PreviewGrid>
        <Helmet>
          <title>404</title>
        </Helmet>
        <Container>
          <Header>
            <MediaAdjustment>
              <div>
                <ImageWithAnimation src={airplane} />
              </div>
              <div>
                <H1>
                  OH SHUCKS !! <Code>404</Code>
                </H1>
                <P>
                  Have no fear, we have a cluster-bunch of articles to read and
                  pictures to look at. Use our nifty navigation bar to get back
                  to where you need to be. We apologize for the inconvenience.
                </P>
              </div>
            </MediaAdjustment>
          </Header>
        </Container>
      </PreviewGrid>
    </Layout>
  )
}
